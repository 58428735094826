.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Login_text{
  color: white !important;
}

.buttonBorder{
  border: solid 1px white !important;
}

.loginTitle{
  color: white;
  font-size: 22px;
  padding-top: 10px;
}

.loginTitle_fr{
  color: white;
  font-size: 25px;
  padding-top: 20px;
}

.Main-banner-login-sec{
  background-color: #00A9E0;
  border: solid 1px;
  border-bottom-right-radius: 10px;
}

.Main-full-banner{
  margin-bottom: 40px;
}

@media (max-width: 960px) {
  .login_side_order {
    -ms-flex-order: 1;
    order: 1;
}
}

@media (max-width: 960px) {
  .banner_side_order {
    -ms-flex-order: 1;
    order: 2;
}
}

.login_container{
 padding-top: 50px;
 padding-left: 40px;
 padding-right: 40px;
 padding-bottom: 20px;
}

@media (max-width: 960px) {
  .impact_container {
    padding:30px;
}
}

.Banner_text_shadow{
  text-shadow: 2px 3px 5px #000000b8;
}

.contact_Dropdown{
  font-size: 18px;
  color: #00A9E0;
  cursor: pointer;
  /* padding-top: 4px; */
  padding-left: 10px;
  margin-bottom: 8px;
}

.contact_Dropdown_ar{
  font-size: 18px;
  color: #00A9E0;
  cursor: pointer;
  /* padding-top: 4px; */
  padding-right: 10px;
  /* padding-left: 5px !important; */
  margin-bottom: 8px;
}

.multiple_Field_Remove{
  /* float:right; */
  color: red;
  cursor: pointer;
}
.uk-button-default:disabled, .uk-button-primary:disabled, .uk-button-secondary:disabled, .uk-button-danger:disabled, .uk-button-success:disabled {
  background-color: transparent;
  color: #999;
  border-color: #e5e5e5;
}

.uk-button-success:disabled:hover {
  background-color: transparent;
  color: #999;
  border-color: #e5e5e5;
}


.multiple_Field_Plus{
  margin-top:5px !important;
  margin-bottom: 5px !important;
}


.multiple_Field_Seperator{
  /* color: #282c34; */
  border-top: 1px solid #787b82
}

.button_Success{
  border-color:#4cae4c !important;
  background-color:#4cae4c !important;
  color:white
}

.verticalAlign{
  vertical-align: inherit !important;
}

.IppfLogo{
  padding-left: 10px !important;
  max-width: 156px !important;
}
.display{
  display:flex !important;
}

.image img {
  width: 36%;
}

.uk-margin-medium-top{
  margin-top:0px  !important;
}

.footerCopyright{
  padding-top:10px !important;
  padding-bottom: 10px !important;
  color: #ffffff;
  background-color: #008C95 !important;
}

.copyRight{
  padding-top:6px !important;
}
.foot{
  padding-bottom: 0px !important;
}



.dropdown .dropbtn {
  /* font-size: 14px;   */
  font-size: 16px;  
  border: none;
  outline: none;
  color: black;
  /* padding: 14px 10px; */
  
  /* commented on July 21st */
  /* padding: 14px 16px; */

  background-color: inherit;
  font-family: inherit;
  text-decoration: none;
  margin: 0;
}


.dropdown .dropbtn_fr {
  /* font-size: 14px;   */
  font-size: 16px;  
  border: none;
  outline: none;
  color: black;
  /* padding: 14px 10px; */
  /* commented above on Aug 10 */

  /* commented on July 21st */
  /* padding: 14px 16px; */

  background-color: inherit;
  font-family: inherit;
  text-decoration: none;
  margin: 0;
}
.register_button_fr{
  padding: 0px 10px !important;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  float: none;
  color: rgb(110, 106, 106);
  padding: 12px 16px;
  text-decoration: none !important;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content_ar {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content_ar a {
  float: none;
  color: rgb(110, 106, 106);
  padding: 12px 10px;
  text-decoration: none !important;
  display: block;
  text-align: right;
  /* padding-right: 5px !important; */
}

.dropdown-content_ar a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content_ar {
  display: block;
}


.dropdown{
  font-family: proxima, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-weight: 800;
  }

.readinessAss{
  background-color: #f0f6fa;
  width: 100%;
  padding: 30px;
  }
.HeaderBorder{
  background-color:#00A9E0 !important ;
  /* border: 2px solid #00A9E0  !; */
  }

.HeaderBorder h4{
  color: white !important;
}

.containerStyle{
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  text-align: justify;
  color: #777777;
  font-weight: 400;
  line-height: 1.6;
  font-size: 17px;
}

.containerMoveBottom{
  margin-top: 40px !important;
}
.containerMoveBottomLogin{
  margin-top: 10px !important;
}

.paddingHeader{
  padding: 0px 15px !important;
  
}



.contactStyle{
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  color: #777777;
  font-weight: 400;
  line-height: 1.6;
  font-size: 17px;
}

.ContactTittle{
  color:#008C95;
}
.contactDetails{
  box-shadow:none !important;
  border:none !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.sizeImage{
  width:4% !important;
  height:4% !important;
}

.button_div .ui.basic.button{
  /* color: #EAAA00 !important; */
  color:#151F6D !important;
  font-weight: bold !important;
  /* border:2px solid #151F6D; */
}

/* .ui.basic.button.outline-button.blueButton{
  background-color: #00A9E0 !important;  
  color:#ffffff !important;
}

.ui.basic.button.outline-button.yellowButton{
  background-color: #EAAA00 !important;  
  color:#ffffff !important;
}

.ui.basic.button.next-button.purpleButton{
  background-color: #151F6D !important;  
  color:#ffffff !important;
} */
.back{
  background: url('./assets/images/Contact_us.jpg') !important;
  background-repeat: no-repeat !important;
  background-size: 100% !important;
}

.main {
  height: 100vh;
  text-align: justify;
  padding-right: 175px;
  font-size: 1.25em;
}


.forgotPassword{
  padding-top: 70px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
 }
 .fPwForm{
  padding-top: 50px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 50px;
 }
.uk-margin.notify{
  background-color: #fb6944 !important;

}
 .notify p{
   color:#ffffff !important;
   text-align: justify !important;
   font-size: medium !important;
 }

 /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important; 
}

/* Firefox */
input[type=number] {
  -moz-appearance:textfield !important;
}
[class*=uk-icon-] {
  font-family: FontAwesome;
  display: inline-block;
  font-weight: 400;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.uk-icon-skype:before {
  content: "\f17e";
}
.uk-icon-user:before {
  content: "\f007";
}
input[disabled] { 
    cursor: default;
    pointer-events: none;
    opacity: .45;
    color: rgba(0,0,0,.87);
 }

 .danger_alert{
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  border-radius: 2px;
  height:"25";
  font-size: 15px;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.success_alert{
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  border-radius: 2px;
  height:"25";
  font-size: 15px;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.warning_alert{
  color: #f2711c;
  background-color: #ffedde;
  border-color: #ffeeba;
  border-radius: 2px;
  height:"25";
  font-size: 15px;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.faq-question-icon,.faq-answer-icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  display:table-cell;
}

.titleFaq{
  display: flex !important;
  position: relative;
  padding-right: 2em;
}

.titleFaq:after{
  position:absolute ;
  right: 0;
}

.titleFaq strong{
  display: table-cell;
}
.titleFaq img{
  max-width: none;
  max-height:none;
  object-fit: cover;
}
.Rowcommon img{
  max-width: none;
  max-height:none;
  object-fit: cover;
}
.Rowcommon{
  /* position: relative; */
  /* display: inline-block; */
  display: flex ;
  padding-right: 2em;
  /* padding-left: 1rem; */
  /* padding-right: 1rem; */
  /* vertical-align: top;
  margin-top: 0;
  margin-bottom: 0; */
}

.faq-accordion .uk-accordion-title:hover,.faq-accordion .uk-accordion-title:focus{
  color: #666;
}
.faq-accordion .uk-accordion-title::before{
  content: "";
  width: 0;
  height: 0;
  margin-left:0;

}


/* Translation_styles_May_19 */

.langDropdownIcon {
  color: black;
  margin-left: -1rem;
  margin-top: -2rem;
  height: 0.2rem;
  width: 0.2rem;
  font-size: 0.8rem;
  display: none;
}
div.ui.dropdown.languageSwitcherLogin div.item {
  font-size: 0.9rem;
  /* padding: 0.7rem !important; */
  /* padding: 14px 0px; */
  /* padding-right: 10px !important; */
  text-align: center;
  /* margin-top: 30px ; */
}

div.ui.dropdown.languageSwitcherLogin div.text {
  font-size: 1rem;
  font-weight: bold !important;
  color: black;
  font-weight: 400;
  text-align: center;
  font-family: proxima;
  /* margin-top: 34px; */
}
div.ui.dropdown.languageSwitcherLogin {
  text-align: center;
  position: absolute;
  margin-left: -5.0rem !important;
  margin-top: -0.1rem !important;
}
.lineHeight {
  line-height: 19.999px !important;
}

div.ui.dropdown.languageSwitcherLogin i.dropdown.icon::before {
  display: none !important;
}

/* //June 2nd */
.incorrect_cred_styles{
  font-size: 12px;
}

.dont_hv_ac_style_fr{
  font-size: 14px !important;
}

.French_updatePW{
  padding-left: 8px !important;
}
.sp_MA_contact{
  font-size: 11.1px !important;
}
.fr_MA_contact{
  font-size: 13px !important;
}
.Ar_MA_contact{
  font-size: 12px !important;
}

.Spanish_style{
font-size: 13px;
}
.french_contact{
  height: 44px;
}




.titleFaq_AR {
  display: flex !important;
  position: relative;
  padding-left: 2.3em;
  margin-right: -0.5rem;
}

.titleFaq_AR:after {
  position: absolute;
  left: 0;
}

.titleFaq_AR strong {
  display: table-cell;
  padding-right: 5px;
}

.Rowcommon_AR {
  display: flex !important;
  padding-left: 2.3em;
  margin-right: -0.5rem;

}
.Rowcommon_AR div{
  padding-right: 5px;
}

.lineHeight .uk-navbar-right .font_size_other{
  font-size: 16px !important;
}

.dropdown_english{
  margin-left: 63px;
}
.dropdown_two_english{
  margin-left: 35px;
}

.dropdown_french{
  margin-left: 28px;
}
.dropdown_two_french{
  margin-left: 10px;
}
.dropdown_spanish{
  margin-left: 63px;
}

.dropdown_two_spanish{
  margin-left: 10px;
}

.dropdown_arabic{
  margin-right: 40px;
}
.dropdown_two_arabic{
  margin-right: 33px;
}
.input_field_sp{
  width: 50%;
  margin-top: 40px;
}

.input_field_other{
  width: 50%;
  margin-top: 22px;
}

.land_loca_other{
  margin-top: 21px !important;
}
.contact_text{
  color: #151F6D;
}

.contact_text_Arabic{
  color: #151F6D;
  padding-right: 5px;
}

.table_style_arabic{
  text-align: right !important;
}
.buttons_arabic_style{
  direction: ltr !important;
  /* border-top-right-radius: .28571429rem;
  border-bottom-right-radius: .28571429rem; */
}

.label_arabic{
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

/* .input_arabic {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right-color: transparent !important;
} */

.input_arabic.ui.labeled.input:not([class*="corner labeled"]) .label:first-child+input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-color: transparent;
}

.text_arabic{
  margin-left: 13px !important;
}
.text_others{
  margin-right: 13px !important;
}
.year_input_arabic{
  width: 90px;
  margin-right:3px;
}

.year_input_others{
  width: 90px;
  margin-left:3px;
}

.input_sp_placeholder{
  margin-top: 21px !important;
  font-size: 14px !important;
}
.input__other_placeholder{
  margin-top: 21px  !important;
  font-size: 15px !important;
}
.Sp_para{
  margin-bottom: -30px !important;
}

.glossary_padding_ar{
  padding-left: 10px !important;
}
.glossary_padding_sp{
  padding-right: 98px !important;
}
.glossary_padding_two_sp{
  padding-right: 10px !important;
}

.glossary_padding_three_sp{
  padding-right: 97px !important;
}

.glossary_padding_others{
  padding-right: 10px !important;
}

.glossary_padding_ar_two{
 padding-left: 6px;
}

.glossary_padding_ar_three{
  padding-left: 70px;
}

.glossary_padding_others_two{
  padding-right: 30px !important;
}

.glossary_padding_two_fr{
  padding-right: 22px !important;
}
.glossary_padding_three_fr{
  padding-right: 95.5px !important;
}
.glossary_padding_others_three{
  padding-right: 70px !important;
}
